import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Card from "../components/Card";
import Hero from "../components/Hero";
import SEO from "../components/MetaSeo";
import Tag from "../components/Tag";
import Search from "../components/Search";

const rows = {
  oneThird: "w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink",
  half: "w-full md:w-1/2 p-6 flex flex-col flex-grow flex-shrink",
  twoThird: "w-full md:w-2/3 p-6 flex flex-col flex-grow flex-shrink",
};

const getRows = (index) => {
  if (index % 3 === 0) return rows.oneThird;
  if (index % 3 === 1) return rows.twoThird;
  if (index % 3 === 2) return rows.half;
};

const IndexPage = ({ data }) => {
  const [hero, ...posts] = data.allWordpressPost.edges.map(({ node }) => node);
  const tags = data.allWordpressTag.edges.map(({ node }) => node);

  return (
    <Layout>
      <SEO />

      <div className="flex flex-col md:pr-10 mb-0 pr-0 w-full md:w-auto text-center bg-black-dark text-white">
        <h2 className="text-xs text-white-500 tracking-widest font-medium title-font mb-1 uppercase">{data.site.siteMetadata.title}</h2>
        <h1 className="md:text-3xl text-2xl font-medium title-font text-white-900 font-family-louize">
          Aprenda História <u>sem ser imposto por uma prova</u>
        </h1>
      </div>
      
      <Hero {...hero} />
  
      <div className="gcse-searchresults-only"></div>



      <section className="container py-5 mx-auto">
        <div className="flex flex-wrap m-4">
          {tags && (
            <div className="px-6 py-4">
              {tags.map((tag) => (
                <Tag key={tag.path} {...tag} />
              ))}
            </div>
          )}
        </div>
      </section>

      <section className="container px-5 py-4 mx-auto">
        <div className="flex flex-wrap">
          {posts.map((node, i) => (
            <Card key={node.slug} {...node} className="w-full md:w-1/3 flex flex-col flex-grow flex-shrink my-2 sm:m-6"  />
          ))}
        </div>
      </section>
 
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteName
        description
      }
    }

    allWordpressTag {
      edges {
        node {
          wordpress_id
          id
          name
          path
          slug
        }
      }
    }
    allWordpressPost(limit: 24) {
      edges {
        node {
          date(formatString: "YYYY/MM/DD")
          title
          slug
          excerpt
          author {
            name
            avatar_urls {
              wordpress_48
            }
          }
          tags {
            name
            slug
            path
          }
          categories {
            name
            slug
            path
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
