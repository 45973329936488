import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";
import moment from "moment";
import "moment/locale/pt";



const Hero = ({ title, featured_media, slug, author, date, categories }) => (
  <>
    <div className="block md:flex md:space-x-2 px-2 py-6 bg-black-dark">
      <Link
        to={slug}
        className=" p-0 mx-auto mb-4 md:mb-0 w-full md:w-2/3 relative rounded inline-block"
        style={{ height: "24em" }}
      >
        <div
          className="absolute left-0 bottom-0 w-full h-full z-10"
          style={{
            backgroundImage:
              "linear-gradient(180deg,transparent,rgba(0,0,0,.7))",
          }}
        ></div>

        {featured_media.localFile && (
          <Img
            className="absolute left-0 top-0 w-full h-full rounded z-0 object-cover"
            alt={slug}
            fluid={featured_media.localFile.childImageSharp.fluid}
          />
        )}

        <div className="p-4 absolute bottom-0 left-0 z-20">
          {categories &&
            categories.map((category) => (
              <span
                key={category.slug}
                className="uppercase inline-block py-1 px-3 my-4 bg-red-dark text-white text-sm font-medium tracking-widest"
              >
                {category.name}
              </span>
            ))}

          <h2
            className="text-4xl font-family-louize text-white leading-tight"
            dangerouslySetInnerHTML={{ __html: title }}
          />

          {/* <div className="flex mt-3">
            <img
              src={author.avatar_urls.wordpress_48}
              className="h-10 w-10 rounded-full mr-2 object-cover"
            />
            <div>
              <p className="font-semibold text-gray-200 text-sm">
                {author.name}{" "}
              </p>
              <p className="font-semibold text-gray-400 text-xs">
                {moment(Date.parse(date)).locale("pt").fromNow()}{" "}
              </p>
            </div>
          </div> */}
        </div>
      </Link>
    </div>
    {/* <section className="bg-cream text-gray-700 body-font">
      <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
          <Link to={slug}>
            {featured_media.localFile && (
              <Img
                className="w-full object-cover object-center rounded"
                alt={slug}
                fluid={featured_media.localFile.childImageSharp.fluid}
              />
            )}
          </Link>
        </div>
        <div className="lg:flex-grow md:w-1/2 md:pl-16 flex flex-col  text-left">
          <h1 className="text-3xl sm:text-4xl font-black leading-tight tracking-tight text-black my-2 underline  hover:no-underline ">
            <Link to={slug}>
              <span
                className="font-family-louize text-blue-navy"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </Link>
          </h1>

          <div
            className="mb-8 leading-relaxed text-blue-navy"
            dangerouslySetInnerHTML={{
              __html: excerpt.replace("[&hellip;]", "..."),
            }}
          />
          <div className="flex justify-center">
            <Link to={slug}>
              <button className="inline-flex text-black  border-0 py-2 px-6 focus:outline-none bg-yellow-500 hover:bg-yellow-600 rounded text-lg">
                Continuar
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section> */}
  </>
);

export default Hero;
